{
  "es6.module": {
    "chrome": "61",
    "and_chr": "61",
    "edge": "16",
    "firefox": "60",
    "and_ff": "60",
    "node": "13.2.0",
    "opera": "48",
    "op_mob": "45",
    "safari": "10.1",
    "ios": "10.3",
    "samsung": "8.2",
    "android": "61",
    "electron": "2.0",
    "ios_saf": "10.3"
  }
}
